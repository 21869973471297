<template>
	<dialog-common ref="dialog" :title="option.title" @ok="confirm">
		<div class="content">

			<div class="number" v-bind:class="{'number-selected':selection==0}">{{value[0]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==1}">{{value[1]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==2}">{{value[2]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==3}">{{value[3]}}</div>
			<div class="text">年</div>
			<div class="number" v-bind:class="{'number-selected':selection==4}">{{value[4]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==5}">{{value[5]}}</div>
			<div class="text">月</div>
			<div class="number" v-bind:class="{'number-selected':selection==6}">{{value[6]}}</div>
			<div class="number" v-bind:class="{'number-selected':selection==7}">{{value[7]}}</div>
			<div class="text">日</div>
		</div>

		<keyboard @clickNum="clickNum" @clickPoint="clickPoint" @clickDelete="clickDelete"></keyboard>

	</dialog-common>
</template>

<script>
	import DialogCommon from "./dialog-common.vue"
	import Keyboard from "./keyboard.vue"
	export default {
		components:{DialogCommon,Keyboard},
		props:["title"],
		data(){
			return {
				selection:0,
				value:["","","","","","","",""],
				option:{
					title:"",
					value:"",
					onConfirm:null
				}
			}
		},
		methods:{
			open(option){
				this.option = option
				this.selection=0
				let value = option.value
				let year = parseInt(value/65535)
				let yearH = parseInt(year/256)
				let yearL = parseInt(year%256)
				let month = parseInt(value%65536 / 256)
				let day = parseInt(value%256)
				this.value = [
					parseInt(yearH/10),
					yearH%10,
					parseInt(yearL/10),
					yearL%10,
					parseInt(month/10),
					month%10,
					parseInt(day/10),
					day%10
				]

				this.$refs.dialog.open()
			},
			close(){
				this.$refs.dialog.close()
			},
			confirm(){
				let year = (this.value[0]*10+this.value[1])*256 + (this.value[2]*10+this.value[3])
				let month = this.value[4]*10+this.value[5]
				let day = this.value[6]*10 + this.value[7]
				let value = year*65536 + month*256 + day
				this.option.onConfirm(value)
			},
			clickNum(num){
				this.value[this.selection] = num
				this.value = [].concat(this.value)
				if(this.selection<7){
					this.selection++
				}

			},
			clickPoint(){

			},
			clickDelete(){
				this.value[this.selection] = ""
				this.value = [].concat(this.value)
				if(this.selection>0){
					this.selection--
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		display: flex;
		align-items: center;
		justify-content: center;

		.number{
			width: 40px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			border: 1px solid #DDD;
			margin: 0 2px;
			font-size: 16px;
			border-radius: 5px;
			background: #F5F5F5;
		}
		.text{
			width: 30px;
			text-align: center;
			font-size: 15px;
		}

		.number-selected{
			border: 1px solid #1b7d28;
		}

	}


</style>
